import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

interface AlertProps {
  message: string;
}

function Alert({ message }: AlertProps) {
  return (
    <div className={styles.alert}>
      <SvgIcon type="FiInfo" className={styles.icon} />

      <span>{message}</span>
    </div>
  );
}

export default Alert;
