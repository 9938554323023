import { CSSProperties } from "react";
import classNames from "classnames";
import Icons from "react-icons/fi";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

interface ISvgButtonProps {
  icon: keyof typeof Icons;
  className?: string;
  iconClassName?: string;
  onClick: () => void;
  style?: CSSProperties;
  isDisabled?: boolean;
}

function SvgButton({
  icon,
  className,
  iconClassName,
  onClick,
  style,
  isDisabled,
}: ISvgButtonProps) {
  return (
    <button
      style={style}
      type="button"
      tabIndex={-1}
      aria-label={icon}
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(styles.button, className, {
        [styles.button_disabled]: isDisabled,
      })}>
      <SvgIcon type={icon} className={classNames(styles.icon, iconClassName)} />
    </button>
  );
}

SvgButton.defaultProps = {
  className: "",
};

export default SvgButton;
