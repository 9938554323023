// Function to parse text and convert markdown links to HTML links.
function parseTextWithLinks(text: string, styles: string) {
  // eslint-disable-next-line no-useless-escape
  const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;

  const parsedText = text.replace(linkRegex, function (match, text, url) {
    return `<a class=${styles} href="${url}" target="_blank" rel="noreferrer">${text}</a>`;
  });

  return parsedText;
}

export default parseTextWithLinks;
