import { createSlice } from "@reduxjs/toolkit";

import { ReservationApi } from "../services/ReservationService";
import { ReservationState } from "./reservationSlice";

const initialState: ReservationState = {
  reservationList: [],
  reservation: {
    status: "",
    pnr: "",
    lead_given_name: "",
    embarkation_date: "",
    ship_name: "",
    cruise_name: "",
    created: "",
    currency: "",
    total_price: 0,
    total_paid: 0,
    lead_email: "",
    lead_lastname: "",
    lead_phone: "",
    lead_title: "",
    sailings: [
      {
        cabins: [
          {
            guests: [
              {
                address: [""],
                city: "",
                country: "",
                date_of_birth: "",
                documentation: [],
                email: "",
                external_code: "",
                extras: [],
                gender: "",
                given_name: "",
                id: 0,
                language: "",
                lastname: "",
                lead_passenger: false,
                nationality: "",
                passenger_type: "",
                phone: "",
                pricing: {
                  category: "",
                  extras: "",
                  fare: "",
                  gft: "",
                  guest: 0,
                  total_fare: "",
                  total_price: 0,
                },
                request: "",
                state: "",
                title: "",
                uuid: "",
                zip_code: "",
              },
            ],
            cabin_number: "",
            uuid: "",
            grade_code: "",
            deck_name: "",
          },
        ],
        commission: "",
        consortia_commission: "",
        cruise_code: "",
        cruise_duration: 0,
        cruise_name: "",
        currency: "USD",
        embarkation_date: "",
        external_rate_code: "",
        external_sailing_code: "",
        extras_price: "",
        fare: "",
        itinerary: [],
        price: "",
        rate_code: "",
        rate_name: "",
        sailing_code: "",
        ship_code: "",
        ship_name: "",
      },
    ],
    payment_schedule: [{ amount: 0, currency: "", due_date: "" }],
    market: "",
    commission: "",
    next_payment_outstanding: "",
    next_payment_due: "",
    transactions: [],
  },
};

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    resetReservationToInitial() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ReservationApi.endpoints.initReservationList.matchFulfilled,
      (state, action) => {
        state.reservationList = action.payload;
      },
    );
    builder.addMatcher(
      ReservationApi.endpoints.retrieveReservation.matchFulfilled,
      (state, action) => {
        state.reservation = action.payload;
      },
    );
  },
});

export const { resetReservationToInitial } = reservationSlice.actions;

export default reservationSlice.reducer;
