// Import React hooks and utilities.
import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames"; // Utility for conditional class names.
import { useTranslation } from "react-i18next"; // Hook for internationalization.

// Redux actions and hooks for state management.
import { Grade } from "../../../../../store/slices/searchSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store";
import { updateRoom } from "../../../../../store/slices/roomsSlice";
import { hideModal } from "../../../../../store/slices/modalSlice";
// Utility functions and hooks.
import { prepareParams } from "../../../../../utils/helpers/rooms";
import useClientPosition from "../../../../../hooks/useClientPosition";

// Shared components.
import CustomCarousel from "../../../../shared/Carousel";
import Button from "../../../../shared/Button";

// Component-specific styles.
import styles from "./index.module.scss";
import { currencyToFormat } from "../../../../../utils/helpers/currency";

// Define the StateroomsModal functional component.
function StateroomsModal() {
  // Redux dispatch hook and navigation hook.
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Internationalization hook.

  // Extract query parameters from the URL.
  const [searchParams] = useSearchParams();
  // Access cruise and modal data from the Redux store.
  const { cruise } = useTypedSelector((state) => state.search);
  const { data } = useTypedSelector((state) => state.modal);
  const { rooms } = useTypedSelector((state) => state.rooms);

  // Cast modal data to a Grade type.
  const grade = data as Grade;
  // Parse total number of rooms and the current room number from URL parameters.
  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  // Memoized value to compute the currently selected room based on room number.
  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }
  }, [rooms, currentRoomNumber]);

  // Function to handle room grade selection and update the Redux store accordingly.
  const handleUpdateChosenGrades =
    (grade: Grade, guestsNumber: number) => () => {
      const updatedStateroom = structuredClone({
        ...room,
        grade,
        guestsNumber,
      });

      dispatch(
        updateRoom({ room: updatedStateroom, roomNumber: currentRoomNumber }),
      );
    };

  // Hook to restore the scroll position using a custom hook.
  const { restorePosition } = useClientPosition();

  // Function to handle form submission, navigating to the next step in the booking process.
  const handleSubmit = () => {
    const pathParam = currentRoomNumber < totalRooms ? "rooms" : "cabin-select";

    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    if (cruise) {
      navigate(`/search-results/${cruise.code}/${pathParam}?${params}`);
    }
    dispatch(hideModal());
    restorePosition();
  };

  // Function to determine the label for the submit button based on the current booking step.
  const getButtonLabel = () => {
    if (currentRoomNumber < totalRooms) {
      return `${t("continue to stateroom")} ${currentRoomNumber + 1}`;
    }

    return `${t("continue to cabins")}`;
  };

  // Render the modal content, displaying the stateroom grade details and a submit button.
  return (
    <div className={styles.container}>
      {/* Carousel component displaying images of the stateroom. */}
      <CustomCarousel
        items={grade?.images || []}
        slideClassName={styles.slide}
      />
      {/* Displays the name of the stateroom grade. */}
      <p className={styles.name}>{grade?.name || ""}</p>
      {/* Container for stateroom location and occupancy details. */}
      <div className={styles.row}>
        {/* Displays the deck location(s) of the stateroom. */}
        <div className={styles.cell}>
          <span className={styles.cellKey}>{t("located:")}</span>

          <span className={styles.cellValue}>{`${t("Deck")} ${(
            grade?.decks || []
          )
            .map((el) => el.code)
            .join(", ")}`}</span>
        </div>

        {/* Displays the maximum occupancy of the stateroom. */}
        <div className={styles.cell}>
          <span className={styles.cellKey}>{t("occupancy")}</span>

          <span className={styles.cellValue}>{`${t("Up to")} ${
            grade?.maximum_occupancy || ""
          } ${t("guests")}`}</span>
        </div>
      </div>
      {/* Displays the first description of the stateroom. */}
      <p className={styles.cellValue}>
        {grade?.descriptions[0]?.description || ""}
      </p>
      {/* Container for the facilities provided in the stateroom. */}
      <div className={styles.block}>
        <p className={styles.cellKey}>{t("facilities")}</p>

        <ul>
          <li className={styles.cellValue}>
            <span>{grade?.size || ""}</span>
          </li>
        </ul>
      </div>
      {/* Section for displaying pricing information per guest number. */}
      <div className={styles.block}>
        <p className={styles.cellKey}>{t("pricing per guest")}</p>

        <div className={styles.grades}>
          {/* Dynamically generates pricing options for 1 to 4 guests. */}
          {[1, 2, 3, 4].map((guestsNumber) => {
            // Determine the label based on the number of guests.
            const guestTitle = guestsNumber === 1 ? t("guest") : t("guests");

            // Extract pricing information for the specific guest count.
            if (!grade) return null;

            const { single, double, triple, quadruple } = grade;

            const pricesByGuestNumber: Record<number, number> = {
              1: single,
              2: double,
              3: triple,
              4: quadruple,
            };

            const price =
              guestsNumber > grade.maximum_occupancy
                ? "N/A"
                : pricesByGuestNumber[guestsNumber];

            // Determine if the pricing option should be displayed as active or disabled.
            const isDisabled = price === "N/A";
            const isActive =
              room?.grade?.code === grade.code &&
              room?.guestsNumber === guestsNumber;

            // Render each pricing option.
            return (
              <div
                key={guestsNumber}
                className={classNames(styles.grade, {
                  [styles.grade_active]: isActive,
                  [styles.grade_disabled]: isDisabled,
                })}
                {...(!isDisabled
                  ? {
                      onClick: handleUpdateChosenGrades(grade, guestsNumber),
                    }
                  : {})}>
                <p
                  className={
                    styles.gradeName
                  }>{`${guestsNumber} ${guestTitle}`}</p>

                <p className={styles.gradePrice}>
                  {isDisabled
                    ? "N/A"
                    : currencyToFormat(
                        price,
                        cruise?.markets.currency ?? "USD",
                      )}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* Container for the button to proceed, with dynamic label based on the
      booking step. */}
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          label={getButtonLabel()}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

// Export the component for use elsewhere.
export default StateroomsModal;
