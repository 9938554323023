// Import useState for local state management, useTranslation for internationalization.
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

// Custom hook for fetching and handling search-related operations.
import useSearch from "../../../hooks/useSearch";
// Hook for dispatching Redux actions.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
// Redux action for creating a new agent record.
import { createAgent } from "../../../store/actions/agencyActions";
// Constants for navigation paths.
import { AGENCY_REGISTRATION } from "../../../utils/constants/routes";
// Utility function for validating agent registration form inputs.
import { validateAgentRegistration } from "../../../utils/validation";
// Types for form input state and handlers.
import titleList from "../../../utils/constants/titleList";
import citizenshipList from "../../../utils/constants/citizenshipList";

import {
  IAgentInputs,
  IValidationAgentInputs,
  InputChangeHandler,
} from "./types";

// Reusable UI components.
import CustomLink from "../../shared/CustomLink";
import Input from "../../shared/Input";
import SearchInput from "../../shared/SearchInput";
import CustomSelect from "../../shared/CustomSelect";
import Button from "../../shared/Button";

// Styles specific to this component.
import styles from "./index.module.scss";

// The main functional component for agent registration.
function AgentRegistration() {
  // Setup dispatch and internationalization hooks.
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  // Destructuring methods from the useSearch hook for agency search.
  const { agencies, searchHandlers, resetHandlers } = useSearch();
  const { agency_name } = useTypedSelector((state) => state.environment);

  // Local state for loading indicator and form inputs.
  const [isLoading, setIsLoading] = useState(false);
  const [titleListForm, setTitleListForm] = useState(titleList);
  // State hook for managing form agentInputs and their error messages.
  const [inputs, setInputs] = useState<IAgentInputs>({
    address: {
      address: { value: { name: "address", value: "" }, errorMessage: "" },
      city: { value: { name: "city", value: "" }, errorMessage: "" },
      state: { value: { name: "state", value: "" }, errorMessage: "" },
      zip: { value: { name: "zip", value: "" }, errorMessage: "" },
      country: { value: { name: "country", value: "" }, errorMessage: "" },
    },
    sales_area: { value: null, errorMessage: "" },
    name: { value: "", errorMessage: "" },
    firstName: { value: "", errorMessage: "" },
    surname: { value: "", errorMessage: "" },
    agency: { value: "", id: "", errorMessage: "" },
    telephone: { value: "", errorMessage: "" },
    position: { value: "", errorMessage: "" },
    email: { value: "", errorMessage: "" },
    b2b_password: { value: "", errorMessage: "" },
    confirm_b2b_password: { value: "", errorMessage: "" },
    is_web: { value: true, errorMessage: "" },
    is_default: { value: true, errorMessage: "" },
    skip_external: { value: true, errorMessage: "" },
  });

  // Preparing lists for form select inputs.
  const countryList = useMemo(() => {
    const countryList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_2,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    return countryList;
  }, []);

  const [countryListForm, setCountryListForm] = useState(countryList);

  // Handler for form submission, which includes validation and dispatching createAgent action.
  const handleCreateAgent = async (validData: IValidationAgentInputs) => {
    setIsLoading(true); // Begin loading.

    const address = Object.entries(inputs.address).map(
      ([_, value]) => value.value,
    );

    const payload = {
      ...validData,
      address,
      agency: Number(validData.agency),
      code: nanoid(16),
    };

    const { confirm_b2b_password, ...finalPayload } = payload;

    await dispatch(
      createAgent({
        ...finalPayload,
        first_name: finalPayload.firstName,
        last_name: finalPayload.surname,
        title: finalPayload.name,
        name: `${finalPayload.name} ${finalPayload.firstName} ${finalPayload.surname}`,
      }),
    );

    setIsLoading(false); // End loading.
  };

  // Handlers for various form input changes.
  const handleAddressChange = ({ value, valueKey }: InputChangeHandler) => {
    const typedValueKey = valueKey as
      | "address"
      | "city"
      | "state"
      | "zip"
      | "country";

    setInputs((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [typedValueKey]: {
          ...prev.address[typedValueKey],
          value: { ...prev.address[typedValueKey].value, value },
          errorMessage: "",
        },
      },
    }));
  };

  // Function to initiate agency search or reset based on input value.
  const searchAgency = async (value: string) => {
    if (value) {
      await searchHandlers.agencySearch(value);
    } else {
      resetHandlers.agenciesReset();
    }
  };

  // General input change handler to update local state.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));

    if (valueKey === "agency") {
      searchAgency(value);
    }
  };

  // Function to handle search input changes and filter the dropdown lists accordingly.
  const handleSearchInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey?: string;
  }) => {
    switch (valueKey) {
      case "name": {
        const filterTitleResult = titleList.filter(
          (titleLabel) =>
            titleLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            titleLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setTitleListForm(filterTitleResult);
        break;
      }

      case "country": {
        const filterCountryResult = countryList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setCountryListForm(filterCountryResult);
        break;
      }

      default: {
        break;
      }
    }
  };

  // Handler for choosing an agency from search results.
  const handleAgencyChose = ({ value }: { value: Record<string, string> }) => {
    setInputs((prev) => ({
      ...prev,
      agency: { errorMessage: "", value: value.name, id: value.id },
    }));
  };

  // Handler for processing form errors after validation.
  const handleFormError = (errors: IValidationAgentInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (errorKey in updatedInputs.address) {
        const value = String(errors[errorKey as keyof IValidationAgentInputs]);

        updatedInputs.address[
          errorKey as keyof Pick<IAgentInputs, "address">
        ].errorMessage = value;
      } else if (errorKey in updatedInputs) {
        const value = String(errors[errorKey as keyof IValidationAgentInputs]);

        updatedInputs[
          errorKey as keyof Omit<IAgentInputs, "address">
        ].errorMessage = value;
      }
    });

    setInputs(updatedInputs);
  };

  const getInputsEntries = (
    inputs: Record<string, any>,
    entries: string[][],
  ) => {
    Object.entries(inputs).forEach(([inputKey, input]) => {
      if (inputKey === "address" && "address" in inputs[inputKey]) {
        getInputsEntries(inputs[inputKey], entries);
      } else if (
        inputKey === "address" ||
        inputKey === "city" ||
        inputKey === "state" ||
        inputKey === "zip" ||
        inputKey === "country"
      ) {
        entries.push([inputKey, input.value.value]);
      } else {
        entries.push([inputKey, input.id ?? input.value]);
      }
    });

    return entries;
  };

  // Handler for form submission, which performs validation and potentially creates an agent.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const entries = getInputsEntries(inputs, []);
    const data = Object.fromEntries(entries);

    validateAgentRegistration({
      data,
      onSuccess: async (validData: IValidationAgentInputs) =>
        await handleCreateAgent(validData),
      onError: (errors: IValidationAgentInputs) => handleFormError(errors),
    });
  };

  // Render the agent registration form.
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("travel advisor registration")}</h1>

        <div className={styles.notes}>
          <div className={styles.note}>
            <p className={styles.noteTitle}>{t("please note")}</p>

            <p className={styles.noteDescription}>
              {t(
                "Your AGENCY must be registered BEFORE completing the Travel Advisor Registration. If your AGENCY is not registered, please contact your business owner and register through the Travel Agency Registration",
              )}{" "}
              <CustomLink to={AGENCY_REGISTRATION} className={styles.link}>
                {t("here")}
              </CustomLink>
            </p>
          </div>

          <div className={styles.note}>
            <p className={styles.noteTitle}>instructions</p>

            <p className={styles.noteDescription}>
              {t(
                "If you are a member of a Host Agency or Franchise and use their IATA/CLIA number for booking purposes, please enter their information below. If you are not, please enter your agency information in both sections",
              )}
            </p>
          </div>
        </div>

        <h2 className={styles.subtitle}>{t("your details")}</h2>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.groupInputs}>
            <SearchInput
              value={inputs.name.value}
              valueKey="name"
              displayKey="label"
              results={titleListForm}
              label={t("Title")}
              placeholder={t("Select a title")}
              errorMessage={inputs.name.errorMessage}
              isMultiple={false}
              onChange={handleSearchInputChange}
              onChosenChange={({ value, valueKey }) =>
                handleInputChange({ value: value.value, valueKey })
              }
              isRequired
            />

            <Input
              value={inputs.firstName.value}
              valueKey="firstName"
              label={t("your first name")}
              placeholder={t("Please tell us your first name")}
              name="firstName"
              errorMessage={inputs.firstName.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <Input
              value={inputs.surname.value}
              valueKey="surname"
              label={t("your surname")}
              placeholder={t("Please tell us your second name")}
              name="surname"
              errorMessage={inputs.surname.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.address.address.value.value}
              valueKey="address"
              label={t("Address")}
              placeholder={t("Agent address")}
              onChange={handleAddressChange}
              errorMessage={inputs.address.address.errorMessage}
              name="address"
              isRequired
            />

            <Input
              value={inputs.address.city.value.value}
              valueKey="city"
              label={t("City")}
              placeholder={t("Agent city")}
              onChange={handleAddressChange}
              errorMessage={inputs.address.city.errorMessage}
              name="city"
              isRequired
            />

            <Input
              value={inputs.address.state.value.value}
              valueKey="state"
              label={t("State")}
              placeholder={t("Agent state")}
              onChange={handleAddressChange}
              errorMessage={inputs.address.state.errorMessage}
              name="state"
              isRequired
            />

            <Input
              value={inputs.address.zip.value.value}
              valueKey="zip"
              label={t("Zip")}
              placeholder={t("Type zipcode")}
              onChange={handleAddressChange}
              errorMessage={inputs.address.zip.errorMessage}
              name="zip"
              isRequired
            />

            <SearchInput
              value={inputs.address.country.value.value}
              valueKey="country"
              displayKey="label"
              results={countryListForm}
              label={t("country")}
              placeholder={t("Select a country")}
              errorMessage={inputs.address.country.errorMessage}
              isLoading={false}
              isMultiple={false}
              onChange={handleSearchInputChange}
              onChosenChange={({ value, valueKey }) =>
                handleAddressChange({ value: value.value, valueKey })
              }
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.telephone.value}
              valueKey="telephone"
              label={t("your phone number")}
              placeholder={t("Your phone number")}
              name="telephone"
              errorMessage={inputs.telephone.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("agency details")}</h2>

              <p className={styles.text}>
                {t(
                  "If you are a member of a Host Agency or Franchise and use their IATA/CLIA number for booking purposes, please enter their information below. If you are not, please enter your agency information",
                )}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <SearchInput
                value={inputs.agency.value}
                valueKey="agency"
                displayKey="name"
                results={agencies.results}
                label={t("agency or company name")}
                placeholder={t("Please tell us your Agency or Company Name")}
                errorMessage={inputs.agency.errorMessage}
                isLoading={agencies.isLoading}
                onChange={handleInputChange}
                onChosenChange={handleAgencyChose}
                isRequired
              />
            </div>

            <div className={styles.groupInputs}>
              <CustomSelect
                items={[
                  { value: "agent", label: "Agent" },
                  { value: "owner", label: "Owner" },
                ]}
                value={inputs.position.value}
                valueKey="position"
                label={t("position")}
                placeholder={t("Please select one")}
                errorMessage={inputs.position.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("account details")}</h2>

              <p className={styles.text}>
                {t("Create an account to access ")}
                {agency_name}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.email.value}
                valueKey="email"
                label={t("your email address")}
                placeholder={t("Your email address")}
                name="email"
                errorMessage={inputs.email.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.b2b_password.value}
                valueKey="b2b_password"
                label={t("password")}
                placeholder={t("Enter a password")}
                name="b2b_password"
                errorMessage={inputs.b2b_password.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />

              <Input
                value={inputs.confirm_b2b_password.value}
                valueKey="confirm_b2b_password"
                label={t("confirm password")}
                placeholder={t("Enter a password confirmation")}
                name="confirm_b2b_password"
                errorMessage={inputs.confirm_b2b_password.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              label={t("complete registration")}
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

// Export the component for use in the application.
export default AgentRegistration;
