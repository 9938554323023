import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";

import sessionMiddleware from "./middlewares/sessionMiddleware";
import searchFilter from "./slices/searchFilterSlice";
import paymentModal from "./slices/paymentModalSlice";
import reservation from "./slices/reservationSlice";
import environment from "./slices/environmentSlice";
import session from "./slices/sessionSlice";
import pricing from "./slices/pricingSlice";
import content from "./slices/contentSlice";
import api from "./services/apiSingleton";
import search from "./slices/searchSlice";
import agency from "./slices/agencySlice";
import guests from "./slices/guestsSlice";
import prices from "./slices/pricesSlice";
import toast from "./slices/toastSlice";
import modal from "./slices/modalSlice";
import rooms from "./slices/roomsSlice";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["rooms", "guests", "prices"],
};

const reducers = {
  [api.payment.reducerPath]: api.payment.reducer,
  [api.search.reducerPath]: api.search.reducer,
  [api.base.reducerPath]: api.base.reducer,
  [api.pos.reducerPath]: api.pos.reducer,
  paymentModal,
  searchFilter,
  reservation,
  environment,
  session,
  pricing,
  content,
  agency,
  guests,
  prices,
  search,
  toast,
  modal,
  rooms,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.base.middleware)
      .concat(api.pos.middleware)
      .concat(api.search.middleware)
      .concat(api.payment.middleware)
      .concat(sessionMiddleware),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
